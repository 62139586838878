import React, { useEffect, useState } from "react";
import Networks from "../../network";
import "./index.scss";
import {
  parseMonth,
  parseYear,
  modelProject,
  modelDashboard,
  fixed,
  modelProjectV2,
  modelDashboardV2,
  isDateMyAccount,
  infinitekitchenSlug,
  luxury29Slug,
} from "./viewModel";
import { useLocation, useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import Loading from "../../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import TableErpRevenue from "./component-parts/TableErpRevenue";
import _ from "lodash"
import ModuleLoading from "../../components/ModuleLoading";

const RevenueDashboard = props => {
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(false);
  const [loadingErpRevenue, setLoadingErpRevenue] = useState(false);
  const [latestRevenues, setLastestRevenues] = useState([]);
  const [date, setDate] = useState(
    query.get("year") && query.get("month")
      ? moment(
          `${query.get("year")} ${query.get("month")} 01`,
          "YYYY MM DD",
        ).toDate()
      : new Date(),
  );
  const [dateDisplay, setDateDisplay] = useState(
    query.get("year") && query.get("month")
      ? moment(
          `${query.get("year")} ${query.get("month")} 01`,
          "YYYY MM DD",
        ).toDate()
      : new Date(),
  );
  const [pinvestRevenue, setPinvestRevenue] = useState(null);
  const [projectRevenues, setProjectRevenues] = useState([]);
  const [erpRevenue, setErpRevenue] = useState(null)

  useEffect(() => {
    let subscribe = { mounted: true };
    setLoading(true);
    _fetchLastestRevenue(
      date,
      subscribe,
      !(query.get("year") && query.get("month")),
    );
    return () => {
      subscribe.mounted = false;
    };
  }, [date]);

  const _fetchLastestRevenue = (date, subscribe, first) => {
    const month = parseMonth(date);
    const year = parseYear(date);
    const query = `?year_month=${year}-${month.no}`;
    let query_erp = query;
    if (isDateMyAccount(date)) {
      Networks.v2
        .getBusinessGroup(first ? "" : query)
        // .then(async res => {
        //   try {
        //     const _month = parseMonth(
        //       moment(res.data.business_groups[0].created_date),
        //     );
        //     const _year = parseYear(
        //       moment(res.data.business_groups[0].created_date),
        //     );
        //     query_erp = `?year_month=${_year}-${_month.no}`
        //     const res1 = await Networks.getBusinessGroup(`?year_month=${_year}-${_month.no}`);
        //     let infinitekitchen = modelDashboard(
        //       res1.data?.business_groups,
        //     )?.find(v => v.slug === "infinite-kitchen");
        //     if (!!infinitekitchen) {
        //       // add key "total_businesses_income" and convert revenue
        //       infinitekitchen.total_businesses_income =
        //         Number(infinitekitchen?.total || 0) * 1000000;
        //       // sum "total_businesses_income" of infinitekitchen to pinvest
        //       res.data.pinvestment_total +=
        //         infinitekitchen.total_businesses_income;
        //       res.data._infinitekitchen = infinitekitchen;
        //     }
        //   } catch {}
        //   return res;
        // })
        .then(res => {
          if (!subscribe.mounted) return;
          if (!!res.data.business_groups?.length || !!res.data.business_projects?.length || !!res.data._infinitekitchen) {
            const revenues: any[] = modelDashboardV2(res?.data?.business_groups || []);
            const projects: any[] = modelProjectV2(res?.data?.business_projects || []);
            if (!!res.data._infinitekitchen) {
              const indexBuInfinitekitchen = revenues?.findIndex(
                v => v.slug === "infinite-kitchen",
              );
              if (indexBuInfinitekitchen > -1) {
                revenues[indexBuInfinitekitchen] =
                  res.data._infinitekitchen || {};
              } else {
                revenues.push(res.data._infinitekitchen);
              }
            }
            setLastestRevenues(revenues);
            setProjectRevenues(projects);
            setPinvestRevenue(fixed(res.data.pinvestment_total));
            setDateDisplay(
              moment(res.data.business_groups[0].created_date)
                .startOf("month")
                .toDate(),
            );
          } else {
            setLastestRevenues([]);
            setPinvestRevenue(null);
            setProjectRevenues(null);
            setLoading(false);
          }
          setLoading(false);
        })
        .catch(() => {
          if (!subscribe.mounted) return;
          setLastestRevenues([]);
          setPinvestRevenue(null);
          setProjectRevenues(null);
          setLoading(false);
        });
    } else {
      Networks.getBusinessGroup(first ? "?mode=latest" : query)
        .then(async res1 => {
          let res2 = null;
          if (first && res1?.data?.length) {
            const month = parseMonth(moment(res1.data[0].created_date));
            const year = parseYear(moment(res1.data[0].created_date));
            setDateDisplay(
              moment(`${year} ${month.no} 01`, "YYYY MM DD").toDate(),
            );
            query_erp = `?year_month=${year}-${month.no}&mode=year-month`
            res2 = await Networks.getPinvestmentRevenue(`?year_month=${year}-${month.no}&mode=year-month`);
          } else {
            res2 = await Networks.getPinvestmentRevenue(
              query + `&mode=year-month`,
            );
          }
          return [res1, res2];
        })
        .then(res => {
          if (!subscribe.mounted) return;
          const revenues: any[] = modelDashboard(res[0].data?.business_groups);
          const projects: any[] = modelProject(res[0].data?.business_projects);
          setLastestRevenues(revenues);
          setProjectRevenues(projects);
          setPinvestRevenue(fixed(res[1].data.earnings));
          setLoading(false);
        })
        .catch(() => {
          if (!subscribe.mounted) return;
          setLastestRevenues([]);
          setPinvestRevenue(null);
          setLoading(false);
        });
    }
    _fetchErpRevenue(query_erp, subscribe)
  };

  const _fetchErpRevenue = (query, subscribe) => {
    setLoadingErpRevenue(true);
    Networks.v2
    .getErpRevenueMonthly(query)
    .then(res => {
      if (!subscribe.mounted) return;
      if (res?.statusOk && res?.data?.length > 0) {
        const { data } = res
        let headers = data.map(d => ({
          CSID: d?.CSID, 
          title: d?.title,
          hasLink: d?.children ? true : false
        }))
        headers.push({
          CSID: null,
          title: 'Total'
        })
        let columns = data.map(d => d.dataGL)
        setErpRevenue(() => ({
          headers,
          columns
        }))
      } else {
        setErpRevenue(null)
      }
      setLoadingErpRevenue(false);
    })
    .catch(() => {
      if (!subscribe.mounted) return;
      setErpRevenue(null);
      setLoadingErpRevenue(false);
    });
  }

  const _onClickItem = (
    v,
    type: "pinvestment" | "group" | "business",
    isInfinitekitchen = false,
    isLuxury29 = false
  ) => {
    const month = parseMonth(dateDisplay);
    const year = parseYear(dateDisplay);
    // if (isInfinitekitchen) {
    //   let prefix = "";
    //   switch (v.slug) {
    //     case "mono-sei-omakase":
    //       prefix = "mono_sei";
    //       break;
    //     case "green-house":
    //       prefix = "greenhouse";
    //       break;
    //     default:
    //       prefix = "";
    //   }
    //   history.push(
    //     `${location.pathname}/infinite-kitchen/${v?.id}?prefix=${prefix}&year=${year}&month=${month?.no}&type=${type}`,
    //   );
    // } else {
    //   history.push(
    //     `${location.pathname}/business/${v?.id}?year=${year}&month=${
    //       month?.no
    //     }&type=${type}&isInktc=${~~isInfinitekitchen}`,
    //   );
    // }
    let prefix = "default";
    if (isInfinitekitchen) {
      // switch (v.slug) {
      //   case "mono-sei-omakase":
      //     prefix = "mono_sei";
      //     break;
      //   case "green-house":
      //     prefix = "greenhouse";
      //     break;
      //   default:
      //     prefix = "default";
      // }
      if (v.slug.startsWith('mono-sei')) {
        prefix = "mono_sei";
      } else if (v.slug.startsWith('green-house')) {
        prefix = "greenhouse";
        if (v.slug === 'green-house-cafe-stadium') {
          prefix = 'greenhouse_cafe_stadium'
        } else if (v.slug === 'green-house-cafe-jasmine') {
          prefix = 'greenhouse_cafe_jasmine'
        }
      }
    }

    if (isLuxury29) {
        prefix = v.slug
    }
    history.push(
      `${location.pathname}/business/${v?.id}?year=${year}&month=${
        month?.no
      }&type=${type}&isInktc=${~~isInfinitekitchen}&isLuxury29=${~~isLuxury29}&prefix=${prefix}`,
    );
  };

  const _onClickProjectItem = v => {
    const month = parseMonth(dateDisplay);
    const year = parseYear(dateDisplay);
    history.push(
      `${location.pathname}/project/${v?.id}?e29proId=${v.my_account_project_id}&year=${year}&month=${month?.no}`,
    );
  };

  const _prevMonth = () => {
    const pd = moment(dateDisplay).add(-1, "months").toDate();
    const month = parseMonth(pd);
    const year = parseYear(pd);
    history.push(`${location.pathname}?year=${year}&month=${month?.no}`);
    setDate(moment(dateDisplay).add(-1, "months").toDate());
    setDateDisplay(moment(dateDisplay).add(-1, "months").toDate());
  };

  const _nextMonth = () => {
    const nd = moment(dateDisplay).add(1, "months").toDate();
    const month = parseMonth(nd);
    const year = parseYear(nd);
    history.push(`${location.pathname}?year=${year}&month=${month?.no}`);
    setDate(moment(dateDisplay).add(1, "months").toDate());
    setDateDisplay(moment(dateDisplay).add(1, "months").toDate());
  };

  const _renderBusinesses = () => {
    if (loading) return null;
    return latestRevenues.map((v, i) =>
      _renderBusinessesGroupItem(v, i, { type: "group" }),
    );
  };

  const _renderBusinessesGroupItem = (v, i, options = {}) => {
    if (["pinvestment", "project"].indexOf(v.slug) < 0 && !v.businesses?.length) {
      return null;
    } else {
      const isLuxury29BuGroup = v.slug === luxury29Slug
      return (
        <div
          key={i}
          style={options?.style || {}}
          className="business-group-card"
          type="button">
          <div
            className="d-flex flex-row mb-3"
            onClick={() =>
              options.disabled
                ? {}
                : _onClickItem(v, options.type, v.slug === infinitekitchenSlug, isLuxury29BuGroup)
            }>
            <div
              style={{
                borderRadius: 4,
                width: 8,
                backgroundColor: v.color,
              }}
            />
            <div
              className={
                "business-group-name ml-2" +
                (!!options.disabled ? " disabled-click" : "")
              }>
              {v.name}
            </div>
            {v.total !== null && (
              <div className="font-unit ml-1">{v.total || 0}</div>
            )}
          </div>
          {v?.businesses?.map((b, i) =>
            _renderBusinessesItem(b, i, { type: "business", group_slug: v.slug }),
          )}
        </div>
      );
    }
  };

  const _renderBusinessesItem = (v, i, options = {}) => {
    return (
      <div
        key={i}
        style={options?.style || {}}
        className="business-card"
        type="button"
        onClick={() =>
          _onClickItem(
            v,
            options.type,
            options.group_slug === infinitekitchenSlug,
            options.group_slug === luxury29Slug,
          )
        }>
        <div className="business-name-container">
          <div className="business-name">{v?.name}</div>
          <div className="font-unit ml-1">{v.revenue?.earnings || 0}</div>
        </div>
      </div>
    );
  };

  const _renderDatePicker = () => {
    return (
      <div className="datepicker-container">
        <div className="btn-prev-next-month" onClick={_prevMonth}>
          <FontAwesomeIcon
            style={{ width: 24, height: 24 }}
            icon="chevron-left"
          />
        </div>
        <div className="date-yearmonth">
          {moment(dateDisplay).format("MMM YYYY")}
        </div>
        <div className="btn-prev-next-month" onClick={_nextMonth}>
          <FontAwesomeIcon
            style={{ width: 24, height: 24 }}
            icon="chevron-right"
          />
        </div>
      </div>
    );
  };

  const _renderPinvestBusiness = () => {
    if (loading || !latestRevenues?.length) return null;

    return (
      <>
        {_renderBusinessesGroupItem(
          {
            name: "Pinvestment Group",
            slug: "pinvestment",
            businesses: [],
            color: "#1890ff",
            total: pinvestRevenue || 0,
          },
          999,
          {
            style: { borderBottom: "none" },
            type: "pinvestment",
            disabled: true,
          },
        )}
        <div className="separate-pinvestment-business" />
      </>
    );
  };

  const _renderProjectItem = (v, i) => {
    return (
      <div
        key={i}
        className="project-card"
        onClick={() => _onClickProjectItem(v)}>
        <div className="project-name-container">
          <div className="project-name">{v?.name}</div>
          <div className="project-daterange ml-1">
            ({v.start_date_format}-{v.end_date_format})
          </div>
        </div>
        <div className="project-expense-container">
          <div className="project-expense">รายได้</div>
          <div className="ml-1">{v.revenue || 0}</div>
        </div>
        <div className="project-expense-container">
          <div className="project-expense">ต้นทุนสะสม</div>
          <div className="ml-1">{v.cumulative_cost || 0}</div>
        </div>
      </div>
    );
  };

  const _renderProjects = () => {
    if (loading || !projectRevenues?.length) return null;

    return (
      <>
        {_renderBusinessesGroupItem(
          {
            name: "Project",
            slug: "project",
            businesses: [],
            color: "#7a32b4",
            total: null,
          },
          998,
          {
            style: { borderBottom: "none" },
            type: "project",
            disabled: true,
          },
        )}
        <div className="business-group-card">
          {projectRevenues?.map((v, i) => _renderProjectItem(v, i))}
        </div>
      </>
    );
  };

  const _renderErpRevenue = () => {
    return (
      <>
        {
          loadingErpRevenue ? 
            <ModuleLoading onlyIcon={true}/> : 
            (erpRevenue !== null && <TableErpRevenue data={erpRevenue}/>)
        }
      </>
    ) 
  }

  const _renderNotFound = () => {
    if (!loadingErpRevenue && !loading && !latestRevenues?.length && !projectRevenues?.length && !erpRevenue) {
      return (
        <div className="revenue-report-error">
          <div className="text-center">ไม่มีข้อมูลของเดือนนี้</div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="page-container revenue-page dashboard">
      <div className="header">
        <h5 className="text-center mb-1">รายงานรายได้</h5>
        {_renderDatePicker()}
      </div>
      <Container
        style={{
          height: "100vh",
          overflowY: "scroll",
        }}>
        {_renderPinvestBusiness()}
        {_renderBusinesses()}
        {_renderProjects()}
        {_renderErpRevenue()}
        {_renderNotFound()}
      </Container>
      <Loading show={loading} />
    </div>
  );
};

export default RevenueDashboard;
